import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";

function App() {
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [url, setUrl] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [devices, setDevices] = useState([]);

  const approvedEmails = [
    "portldemo@gmail.com",
    "nolancassidy1@gmail.com",
    "bkaplan70@gmail.com",
    "ascheckel@codebaby.com",
  ];

  const apps = [
    {
      name: "ProtoBot",
      url: "https://protoavatar.herokuapp.com/",
    },
    {
      name: "Doctor Proto Bot",
      url: "https://protoavatar.herokuapp.com/?=doctor1",
    },
    {
      name: "Doctor Education/Consent",
      url: "https://protoavatar.herokuapp.com/?=doctor3",
    },
    {
      name: "Doctor Home Care",
      url: "https://protoavatar.herokuapp.com/?=doctor4",
    },
    {
      name: "Doctor Staff Training",
      url: "https://protoavatar.herokuapp.com/?=doctor5",
    },
    {
      name: "Doctor Check In",
      url: "https://protoavatar.herokuapp.com/?=doctor2",
    },
    {
      name: "Ahsoka",
      url: "https://starwars.herokuapp.com/",
    },
    {
      name: "QA ProtoBot",
      url: "https://protoavatar.herokuapp.com/?=qa",
    },
    {
      name: "QA Ahsoka",
      url: "https://starwars.herokuapp.com/?=qa",
    },
    {
      name: "Raffi",
      url: "https://protoavatar.herokuapp.com/?=raffi",
    },
    {
      name: "VW Builder",
      url: "https://www.vw.com/en/builder.html",
    },
    {
      name: "Heart",
      url: "https://sketchfab.com/3d-models/heart-animated-1582359649694207a2ad6bd0ebd0606a",
    },
    {
      name: "Nike Shoe",
      url: "https://www.nike.com/u/nike-air-max-90-futura-by-you-custom-shoes-10001616/1690398504020",
    },
    {
      name: "Bulgari Watch",
      url: "https://www.bulgari.com/en-us/watches/womens/serpenti-tubogas-watch-yellow-gold-steel-gold-103648",
    },
    {
      name: "Mekorama Game",
      url: "https://play.mekorama.com/",
    },
    {
      name: "Roben PGIM 3",
      url: "https://roben-pgim-18c2a77f2862.herokuapp.com/?=3",
    },
    {
      name: "Medtronic S7 Bot",
      url: "https://medtronic-s7-68309ee86b8d.herokuapp.com/",
    },
    {
      name: "Scott PWC Bot",
      url: "https://scott-pwc-e05ba705e60e.herokuapp.com/",
    },
    {
      name: "Quest 3",
      url: "https://protoquest3-04a1dce477a6.herokuapp.com/",
    },
    {
      name: "Concierge",
      url: "https://protoavatar.herokuapp.com/?=concierge",
    },
  ];

  const verizonapps = [
    {
      name: "ProtoBot",
      url: "https://protoavatar.herokuapp.com/",
    },
    { name: "Doctor Bot", url: "https://protoavatar.herokuapp.com/?=doctor1" },
    {
      name: "Nike Shoe",
      url: "https://www.nike.com/u/nike-air-max-90-futura-by-you-custom-shoes-10001616/1690398504020",
    },
    {
      name: "Mekorama Game",
      url: "https://play.mekorama.com/",
    },
  ];

  const pgimapps = [
    {
      name: "ProtoBot",
      url: "https://protoavatar.herokuapp.com/",
    },
    { name: "Doctor Bot", url: "https://protoavatar.herokuapp.com/?=doctor1" },
    {
      name: "Roben Bot",
      url: "https://roben-pgim-18c2a77f2862.herokuapp.com/?=3",
    },
  ];

  const medtronicapps = [
    {
      name: "Human Biodigital",
      url: "https://human.biodigital.com/login",
    },
  ];

  const pwcapps = [
    {
      name: "Scott PWC Bot",
      url: "https://scott-pwc-e05ba705e60e.herokuapp.com/",
    },
  ];

  const prodigalapps = [
    {
      name: "ProtoBot",
      url: "https://protoavatar.herokuapp.com/",
    },
    { name: "Doctor Bot", url: "https://protoavatar.herokuapp.com/?=doctor1" },
    {
      name: "Nike Shoe",
      url: "https://www.nike.com/u/nike-air-max-90-futura-by-you-custom-shoes-10001616/1690398504020",
    },
    {
      name: "Mekorama Game",
      url: "https://play.mekorama.com/",
    },
  ];

  const handleDeviceClick = (sn) => {
    if (serialNumber !== sn) {
      setSerialNumber(sn);
    } else {
      setSerialNumber("");
    }
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    const data = { email, password };
    localStorage.setItem("email", email);
    fetch("https://api.protohologram.com/auth/sign-in", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Incorrect email or password");
        }
      })
      .then((data) => {
        // console.log("Got the token", data);
        setToken(data.accessToken);
        localStorage.setItem("token", data.accessToken);
        getDevices(data.accessToken);
      })
      .catch((error) => {
        console.error("Error:", error.message);
        const warningMsg = document.querySelector("#warningMsg");
        warningMsg.style.color = "red";
        warningMsg.textContent = error.message;
      });
  };

  const getDevices = (accessToken = null) => {
    fetch(
      "https://api.protohologram.com/devices?sortOrder=DESC&orderBy=lastOnline&limit=99&page=0",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken ? accessToken : token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Got the devices", data.rows);
        const filteredDevices = data.rows.filter(
          (device) => device.platform === "linux"
        );
        setDevices(filteredDevices);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getDevices2 = () => {
    fetch(
      "https://api.protohologram.com/devices?sortOrder=DESC&orderBy=lastOnline&limit=99&page=0",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Got the devices", data.rows);
        const filteredDevices = data.rows.filter(
          (device) => device.platform === "linux"
        );
        setDevices(filteredDevices);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSendRequest = (e) => {
    e.preventDefault();

    const data = {
      action: "BROWSER",
      browserUrl: url,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    const api = `https://api.protohologram.com/devices/${serialNumber}/content/playback`;

    fetch(api, options)
      .then((response) => {
        if (response.status === 201) {
          // Empty response with a 201 status code.
          return Promise.resolve({});
        } else if (!response.ok) {
          // An error occurred.
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
      })
      .then((data) => {
        console.log("Playback Request Sent Successfully", data);
        try {
          fetch("/api/urls", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              url: url?.toLowerCase(),
              email: email?.toLowerCase(),
            }),
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                console.log("Log Error");
              }
            })
            .then((data) => {
              console.log("Log Success");
            })
            .catch((error) => {
              console.log("Log Error");
            });
        } catch (error) {
          console.log("Log Error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSendUrl = (appUrl) => {
    const data = {
      action: "BROWSER",
      browserUrl: appUrl,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    const api = `https://api.protohologram.com/devices/${serialNumber}/content/playback`;

    fetch(api, options)
      .then((response) => {
        if (response.status === 201) {
          // Empty response with a 201 status code.
          return Promise.resolve({});
        } else if (!response.ok) {
          // An error occurred.
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
      })
      .then((data) => {
        console.log("Playback Request Sent Successfully!", data);
      })
      .catch((error) => {
        console.log("Log Error");
      });
  };

  const handleStop = (appUrl) => {
    const data = {
      action: "STOP",
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    const api = `https://api.protohologram.com/devices/${serialNumber}/content/playback`;

    fetch(api, options)
      .then((response) => {
        if (response.status === 201) {
          // Empty response with a 201 status code.
          return Promise.resolve({});
        } else if (!response.ok) {
          // An error occurred.
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
      })
      .then((data) => {
        console.log("Playback Request Sent Successfully", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleLogOut = () => {
    setToken("");
    localStorage.removeItem("token");
  };

  const [isSpinning, setIsSpinning] = useState(false);

  const handleRefreshClick = () => {
    setIsSpinning(true);
    getDevices2();
    setTimeout(() => {
      setIsSpinning(false);
    }, 500);
  };

  useEffect(() => {
    if (token) {
      getDevices();
    }
  }, [token]);

  return (
    <div className="App">
      {!token ? (
        <>
          <img className="logo" src="plogo.png" />
          <div className="loginback">
            <form onSubmit={handleSignIn}>
              <label className="email">
                <h4>Email:</h4>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>

              <label>
                <h4>Password:</h4>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
              <button type="submit" className="buttonprim">
                Log In
              </button>
              <p id="warningMsg"></p>
            </form>
          </div>
        </>
      ) : (
        <div>
          <div className="app-submission-section">
            <h3 className="app-submission-title">
              Done Developing? Add Your App to the App Store
            </h3>
            <p className="app-submission-info">
              If you have developed an app that you frequently use, consider
              submitting it to our platform. Use this{" "}
              <a
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSddnCt8V6_q7ZjEQTf81rPJo0LS-UW0V6NE8CdDOBiE5rKlFw/viewform"
                className="app-submission-link"
              >
                form
              </a>{" "}
              for submission and we will review your app and You can set it as
              public, private, or a paid app. This allows you to launch it
              directly from your device or through Proto Cloud.
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSddnCt8V6_q7ZjEQTf81rPJo0LS-UW0V6NE8CdDOBiE5rKlFw/viewform"
              class="app-store-submit-button"
              target="_blank"
            >
              Submit to App Store
            </a>
          </div>
          <form onSubmit={handleSendRequest}>
            <h4>
              <img
                src="refresh.png"
                className={`refresh ${isSpinning ? "spin-animation" : ""}`}
                onClick={handleRefreshClick}
              />
              Select Device:
            </h4>
            {devices.length === 0 ? (
              <a>
                This account has no M devices. Please sign into
                https://cloud.protohologram.com/ to register a device. Or ask
                the M owner to share the device to your email.{" "}
              </a>
            ) : (
              <>
                <div className={serialNumber ? "devices" : "devices2"}>
                  {devices?.map((device) => (
                    <div
                      className={`device-button ${
                        serialNumber === device.serialNumber
                          ? "highlighted"
                          : ""
                      } ${
                        device.updateState !== "UP_TO_DATE"
                          ? "disabled"
                          : "notdisabled"
                      }`}
                      key={device.serialNumber}
                      onClick={
                        device.updateState === "UP_TO_DATE"
                          ? () => handleDeviceClick(device.serialNumber)
                          : undefined
                      }
                    >
                      {device.serialNumber} - {device.name}
                      <br />
                      {device.isOnline ? (
                        <span className="status-circle green-circle"></span>
                      ) : (
                        <span className="status-circle red-circle"></span>
                      )}
                      {device.isOnline ? "Online" : "Offline"}
                      {device.updateState !== "UP_TO_DATE" && (
                        <div className="hover-popup">Update required</div>
                      )}
                    </div>
                  ))}
                </div>
                <a className="serialnum">
                  Selected Device: {serialNumber ? serialNumber : "None"}
                </a>
              </>
            )}
            {serialNumber && (
              <>
                <h4>Launch URL</h4>
                <div>
                  <input
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="urlinput"
                  />
                  <button
                    style={{
                      pointerEvents: url ? "auto" : "none",
                      opacity: url ? 1 : 0.5,
                    }}
                    type="submit"
                  >
                    Launch
                  </button>
                  <button
                    style={{ marginLeft: "10px" }}
                    onClick={() => handleStop()}
                  >
                    Close
                  </button>
                </div>
              </>
            )}
          </form>
          {serialNumber &&
            (email.toLowerCase().endsWith("protohologram.com") ||
              email.toLowerCase().endsWith("portlhologram.com") ||
              approvedEmails.includes(email.toLowerCase())) && (
              <>
                <h4>My Apps</h4>
                {apps?.map((app) => (
                  <button
                    className={"webApp"}
                    onClick={() => handleSendUrl(app.url)}
                  >
                    {app.name}
                  </button>
                ))}
                <br />
              </>
            )}

          {serialNumber && email.toLowerCase().endsWith("medtronic.com") && (
            <>
              <h4>My Apps</h4>
              {medtronicapps?.map((app) => (
                <button
                  className={"webApp"}
                  onClick={() => handleSendUrl(app.url)}
                >
                  {app.name}
                </button>
              ))}
              <br />
            </>
          )}

          {serialNumber &&
            email.toLowerCase().endsWith("verizonwireless.com") && (
              <>
                <h4>My Apps</h4>
                {verizonapps?.map((app) => (
                  <button
                    className={"webApp"}
                    onClick={() => handleSendUrl(app.url)}
                  >
                    {app.name}
                  </button>
                ))}
                <br />
              </>
            )}

          {serialNumber && email.toLowerCase().endsWith("@pgim.com") && (
            <>
              <h4>My Apps</h4>
              {pgimapps?.map((app) => (
                <button
                  className={"webApp"}
                  onClick={() => handleSendUrl(app.url)}
                >
                  {app.name}
                </button>
              ))}
              <br />
            </>
          )}

          {serialNumber &&
            (email.toLowerCase().endsWith("@prodigalcompany.com") ||
              email.toLowerCase().endsWith("@humtown.com")) && (
              <>
                <h4>My Apps</h4>
                {prodigalapps?.map((app) => (
                  <button
                    className={"webApp"}
                    onClick={() => handleSendUrl(app.url)}
                  >
                    {app.name}
                  </button>
                ))}
                <br />
              </>
            )}

          {serialNumber && email.toLowerCase().endsWith("@pwc.com") && (
            <>
              <h4>My Apps</h4>
              {pwcapps?.map((app) => (
                <button
                  className={"webApp"}
                  onClick={() => handleSendUrl(app.url)}
                >
                  {app.name}
                </button>
              ))}
              <br />
            </>
          )}

          <button className={"logout"} onClick={() => handleLogOut()}>
            Log Out
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
